////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(218, 218, 218, 1);
}
.bg-secondaryColor {
  background-color: rgba(218, 218, 218, 1);
}
.bg-thirdColor {
  background-color: rgba(142, 11, 22, 1);
}
.bg-fourthColor {
  background-color: rgba(142, 11, 22, 1);
}
.bg-fifthColor {
  background-color: rgba(87, 16, 31, 1);
}
.bg-sixthColor {
  background-color: rgba(142, 11, 22, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 255, 255, 1);
}
